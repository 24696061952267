export const environment = {
  production: true,
  apiUrl: 'https://zna-api.zurichna.com/ext/dcit/dcit-api/api',
  subletUrl: 'https://zna-api.zurichna.com/ext/dcit/dcit-api/api',
  environmentName: 'znaprod',
  isOktaEnabled: true,
  APISubscriptionKey: '7416276b79c84f7cb8c995878ac642a9',
  CLIENT_ID: '0oabvlh652xVYS9bw357',
  ISSUER: 'https://login.zurichna.com/oauth2/ausbvmqyi0vncU8tQ357',
  salesforceUrl: "https://zurich.my.salesforce.com/",
  exportButtonVisible: false
};